import React, {
  Fragment,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Form, Field, FormSpy } from "react-final-form";
import styled from "styled-components";
import PropTypes from "prop-types";
import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";

import AutoComplete from "components/AutoCompleteInput";
import { ButtonWrapper } from "components/ListComponents";
import ModalSchema from "components/Modal/./_Schema";
import validator, { composeValidators } from "components/Validation";

import { media } from "style/style-utils";
import {
  StyledInputBox,
  StyledTextareaBox,
  StyledInputCheckBoxRadio,
  StyledLabel,
} from "style/styleComponents/Form/input";

import { getFullResidencePlace } from "../common/index.js";
import { SpaceBlock, TwoInputWrapper } from "../AnalysisForm.style";
const StyledModal = styled(Modal)`
  & .modal-title {
    display: flex;
    justify-content: space-between;
  }
`;
StyledModal.displayName = "Modal";

const DeleteButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default function SimpleAcceptModal(props) {
  const {
    accept,
    closeModal,
    data,
    getUser,
    open,
    openAddModal,
    readOnly,
    title,
    user,
  } = props;

  const handleAccept = useCallback(() => {
    accept();
    closeModal();
  }, [accept, closeModal]);

  const handleClose = useCallback(() => {
    closeModal();
  }, [accept, closeModal]);

  console.log("user", user);

  const full_name = useMemo(() => {
    if (user.full_name) {
      return user.full_name;
    }
    return `${user.first_name || ""} ${user.last_name || ""}`;
  }, [user]);

  const resident_fulladdress = useMemo(() => {
    if (user.resident_fulladdress) {
      return user.resident_fulladdress;
    }

    return getFullResidencePlace(user);
  }, [user]);

  return (
    <div className="static-modal">
      <StyledModal show={open} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <TwoInputWrapper
              style={{
                width: "100%",
              }}
            >
              <div>{title}</div>
              {readOnly && (
                <DeleteButtonWrapper>
                  {" "}
                  <Button
                    bsStyle="danger"
                    id="ModalListClose"
                    onClick={handleClose}
                  >
                    X
                  </Button>
                </DeleteButtonWrapper>
              )}
            </TwoInputWrapper>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!readOnly && (
            <Form
              initialValues={{
                user,
              }}
              onSubmit={() => {}}
              render={({ handleSubmit, pristine, invalid, values, form }) => {
                return (
                  <Fragment>
                    <Field
                      name={`user`}
                      render={({ input, meta, ...rest }) => (
                        <SpaceBlock>
                          <AutoComplete
                            maxListHeight={"260px"}
                            placeholder={"Wyszukaj pracownika w systemie"}
                            wrapperStyleFullLength
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : null
                            }
                            data={data}
                            value={input.value.full_name}
                            propValue={"full_name"}
                            propName={"full_name"}
                            selectOnBlur={false}
                            handleSelect={(value) => {
                              form.reset();
                              getUser(value.id);
                              input.onChange(value);
                            }}
                          />
                        </SpaceBlock>
                      )}
                    />
                  </Fragment>
                );
              }}
            />
          )}
          {true ? (
            <>
              <TwoInputWrapper>
                <StyledInputBox
                  disabled
                  value={full_name}
                  label="Imię i nazwisko"
                />

                <StyledInputBox
                  value={user.date_birth || user.birth_date || ""}
                  label="Data urodzenia"
                  disabled
                />
              </TwoInputWrapper>
              <TwoInputWrapper>
                <StyledInputBox
                  value={resident_fulladdress}
                  label="Adres zamieszkania"
                  disabled
                />
                <StyledInputBox
                  value={user.pesel || ""}
                  label="PESEL"
                  disabled
                />
              </TwoInputWrapper>
              <TwoInputWrapper>
                <StyledInputBox
                  value={user.id_document_number || ""}
                  label="Nr dokumentu potwierdzającego tożsamość"
                  disabled
                />
                <StyledInputBox
                  value={user.section_name || ""}
                  label="Dział zatrudnienia"
                  disabled
                />
              </TwoInputWrapper>
              <TwoInputWrapper>
                <StyledInputBox
                  value={user.workplace_name || ""}
                  label="Stanowisko pracy"
                  disabled
                />
              </TwoInputWrapper>
            </>
          ) : (
            <>
              <TwoInputWrapper>
                <StyledInputBox
                  value={`${user.first_name || ""} ${user.last_name || ""}`}
                  label="Imię i nazwisko"
                />
              </TwoInputWrapper>

              <TwoInputWrapper>
                <StyledInputBox
                  value={user.section_name || ""}
                  label="Dział zatrudnienia"
                  disabled
                />
              </TwoInputWrapper>
              <TwoInputWrapper>
                <StyledInputBox
                  value={user.workplace_name || ""}
                  label="Stanowisko pracy"
                  disabled
                />
              </TwoInputWrapper>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!readOnly && (
            <TwoInputWrapper>
              <div
                style={{
                  flexBasis: "auto",
                }}
              >
                <Button type="button" bsStyle="primary" onClick={openAddModal}>
                  Załóż nowe konto
                </Button>
              </div>
              <div>
                <Fragment>
                  <Button onClick={handleAccept} bsStyle="primary">
                    Zapisz
                  </Button>
                  <Button type="button" onClick={handleClose}>
                    Anuluj
                  </Button>
                </Fragment>
              </div>
            </TwoInputWrapper>
          )}
        </Modal.Footer>
      </StyledModal>
    </div>
  );
}

SimpleAcceptModal.propType = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
