import React from "react";
import styled from "styled-components";
import Button from "react-bootstrap/lib/Button";
import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { OnChange } from "react-final-form-listeners";

import arrayMutators from "final-form-arrays";

import AppComponent from "components/AppComponent";
import Box from "components/Box";
import { ButtonWithIcon } from "components/ListComponents";
import {
  FieldInput,
  FieldSelect,
  FieldDayPicker,
  FieldInputCheckBox,
  FieldDayPicker33,
  FieldTextareaBox,
  FieldTextEditor,
  FieldArrayComponent,
} from "components/Fields";
import { LayoutContent } from "components/Layout";
import { LinkContainer } from "components/LinkContainer";
import { StyledLabel } from "style/styleComponents/Form/input";

import GetDraft from "./GetDraft";
import {
  ButtonWrapper,
  LeftPadding,
  Section,
  TwoInputWrapper,
} from "components/Wrappers";

import validator from "components/Validation";
import TextEditor2 from "../../TextEditor";

let TeamLine = styled.div`
  display: flex;
  margin: 0 -10px;
  & > * {
    padding: 10px;
    &:nth-child(1) {
      width: 40%;
    }
    &:nth-child(2) {
      width: 40%;
    }
    &:nth-child(3) {
      width: 20%;
    }
  }
`;

export default class BasicForm extends AppComponent {
  constructor(props) {
    super(props);

    this.state = { ...this.state, draft_source_id: null, cache: {} };

    this.getRAData = this.getRAData.bind(this);
    this.submit = this.submit.bind(this);
    this.updateForm = this.updateForm.bind(this);
  }

  getRAData(id, cb) {
    let { getRAData, methods } = this.props;
    getRAData(id, (data) => {
      const newData = { ...data };
      this.setState({ draft_source_id: id });
      console.log("getRAData", data, methods);
      if (newData.ra_method_id) {
        const existMethod = methods.find((method) => {
          return method.id === newData.ra_method_id;
        });
        if (!existMethod) {
          delete newData.ra_method_id;
        }
      }

      this.updateForm(newData);
      cb();
    });
  }

  updateForm(values) {
    let form = this.form;
    let data = [
      "name",
      "goal_scope",
      "legal_basis",
      "definitions",
      "content",
      "ra_method_id",
      "team",
    ];

    for (let i of data) {
      form.change(i, values[i]);
    }
  }

  submit(data) {
    let { draft_source_id } = this.state;
    let { submit } = this.props;

    submit({ ...data, draft_source_id });
  }

  render() {
    let {
      draft,
      getRAData,
      data,
      id,
      methods,
      permission = {},
      raDraftList,
      readOnly,
      SideMenu,
      submit,
      update,
      urls,
      readOnlyState,
      mode = 1,
      message,
    } = this.props;
    const methodMap = new Map(methods.map((i) => [i.id, i]));
    return (
      <LayoutContent
        error404={this.state.error_code == 404}
        message={message}
        SideMenu={SideMenu}
      >
        {!readOnlyState && !draft && (
          <ButtonWrapper>
            <GetDraft data={raDraftList} getData={this.getRAData} />
          </ButtonWrapper>
        )}
        <Form
          onSubmit={this.submit}
          mutators={{ ...arrayMutators }}
          initialValues={data}
          render={({ handleSubmit, pristine, invalid, values = {}, form }) => {
            this.form = form;
            return (
              <Box
                title={"Dodaj nową ocenę ryzyka zawodowego - Informacje ogólne"}
              >
                <form onSubmit={handleSubmit}>
                  <Section>
                    <FieldInput
                      disabled={readOnly}
                      validate={validator.required}
                      name="name"
                      label="Nazwa oceny ryzyka zawodowego"
                    />
                    <TwoInputWrapper>
                      <TwoInputWrapper>
                        <FieldDayPicker
                          disabled={readOnly}
                          name="date_execution"
                          label="Data wykonania oceny"
                        />
                        {update ? (
                          <FieldDayPicker
                            disabled={readOnly}
                            name="date_update"
                            label="Data aktualizacji"
                          />
                        ) : null}
                      </TwoInputWrapper>
                    </TwoInputWrapper>
                    <FieldInputCheckBox
                      disabled={readOnly}
                      name="reminder_date_update_next"
                      label="Czy przypominać o okresowej aktualizacji ORZ"
                    />
                    <LeftPadding>
                      <FieldDayPicker33
                        disabled={readOnly}
                        name="date_update_next"
                        label="Data następnej aktualizacji"
                      />
                    </LeftPadding>
                    {!draft ? (
                      <>
                        <FieldInput
                          disabled={readOnly}
                          name="symbol"
                          label="Symbol karty"
                        />
                      </>
                    ) : null}
                    <FieldSelect
                      disabled={readOnly || data.id}
                      name="ra_method_id"
                      label="Metoda oceny ryzyka zawodowego"
                      validate={validator.required}
                      data={methods}
                    />
                    {!id ? (
                      <OnChange name="ra_method_id">
                        {(value) => {
                          if (methodMap.has(value)) {
                            let method = methodMap.get(value);
                            form.change("goal_scope", method.goal_scope);
                            form.change("legal_basis", method.legal_basis);
                            form.change("definitions", method.definitions);
                            form.change("content", method.content);
                          }
                        }}
                      </OnChange>
                    ) : (
                      ""
                    )}
                  </Section>
                  <h4>Zespół oceniający ryzyko zawodowe</h4>
                  <LeftPadding>
                    <FieldArray name="team">
                      {({ fields }) => (
                        <>
                          <TeamLine>
                            <StyledLabel>Imię i nazwisko</StyledLabel>
                            <StyledLabel>Funkcja</StyledLabel>
                          </TeamLine>

                          {fields.map((name, index) => (
                            <TeamLine>
                              <FieldInput
                                disabled={readOnly}
                                name={`${name}.name`}
                              />
                              <FieldInput
                                disabled={readOnly}
                                name={`${name}.role`}
                              />
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <ButtonWithIcon
                                  disabled={readOnly}
                                  icon="fa fa-trash"
                                  onClick={() => {
                                    fields.remove();
                                  }}
                                />
                              </div>
                            </TeamLine>
                          ))}
                          <ButtonWrapper>
                            <Button
                              disabled={readOnly}
                              onClick={() => {
                                fields.push({});
                              }}
                            >
                              Dodaj osobę
                            </Button>
                          </ButtonWrapper>
                        </>
                      )}
                    </FieldArray>
                  </LeftPadding>

                  <h4>Informacje dodatkowe</h4>
                  <LeftPadding>
                    <FieldTextareaBox
                      disabled={readOnly}
                      name="goal_scope"
                      label="Cel i zakres oceny ryzyka zawodowego"
                    />
                    <FieldTextareaBox
                      disabled={readOnly}
                      name="legal_basis"
                      label="Podstawy prawne"
                    />
                    <FieldTextareaBox
                      disabled={readOnly}
                      name="definitions"
                      label="Definicje"
                    />
                    <Field
                      name="content"
                      render={({ input, meta, ...rest }) => (
                        <>
                          <StyledLabel>
                            Charakterystyka wykorzystanej metody oceny ryzyka
                            zawodowego
                          </StyledLabel>
                          <TextEditor2
                            disabled={readOnly}
                            label="Treść"
                            data={input.value}
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : null
                            }
                            onChange={(value) => {
                              input.onChange(value);
                            }}
                          />
                        </>
                      )}
                    />

                    {/* <FieldTextEditor
                      disabled={readOnly}
                      name="content"
                      label="Charakterystyka wykorzystywanej metody oceny ryzyka zawodowego"
                    /> */}
                  </LeftPadding>
                  <ButtonWrapper>
                    {readOnly ? (
                      <LinkContainer to={urls.DANGER(id)}>
                        <Button disabled={!id} type="button">
                          Dalej
                        </Button>
                      </LinkContainer>
                    ) : (
                      <Button type="submit">Dalej</Button>
                    )}
                  </ButtonWrapper>
                </form>
              </Box>
            );
          }}
        />
      </LayoutContent>
    );
  }
}
