import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import moment from "moment";
import Alert from "react-bootstrap/lib/Alert";
import Col from "react-bootstrap/lib/Col";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";

// import { getSections, clearSections } from "actions/sectionActions";
import { getWorkplacePermissions } from "actions/userActions";

import Box from "../Box";
import { LayoutContent } from "../Layout";
import {
  StyledInputBox,
  StyledInput,
  StyledInputCheckBox,
  StyledLabel,
} from "../../style/styleComponents/Form/input";
import { ButtonWrapper } from "../ListComponents";
import Button from "react-bootstrap/lib/Button";
import CheckboxListOfGroups from "../CheckboxListOfGroups";
import AddQE from "components/QE/AddQE";
import { initialPermissionValues } from "components/Workplace.form/permissionsList";

import DayPicker from "../../lib/DayPicker";

import AppComponent from "components/AppComponent";
import { LinkContainer } from "components/LinkContainer";
import { FieldTextareaBox } from "components/Fields";

import MESegment from "components/MedicalExamination/MEUserSegment";
import OSHSegment from "components/OSH/OSHSegment";
import SaveMedicalExaminationsFormModal from "components/MedicalExamination/SaveMedicalExaminationsFormModal";
import ModalDelete from "components/Modal/SimpleAccept";
import AddOSH from "components/OSH/AddOSH";
import QEUserSegment from "components/QE/QEUserSegment";

import dataToFormData from "../dataToFormData";
import { media } from "../../style/style-utils";
import DefaultDataComponent from "./DefaultDataComponent";
import DetailDataComponent from "./DetailDataComponent";

import OSHTrainingComponent from "./OSHTrainingComponent";

import Permissions from "./Permissions";

const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

const BoxesWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
  flex-wrap: wrap;

  & > * {
    padding: 0 10px;
    flex-basis: 50%;
    ${media.sm`
      flex-basis: 100%;
      margin-bottom:10px;
    `}
  }
`;
BoxesWrapper.displayName = "BoxesWrapper";
const CheckboxWrapper = styled.div``;
CheckboxWrapper.displayName = "CheckboxWrapper";
const mutatorSetTrue = ([fields], state, { changeValue }) => {
  fields.forEach((name) => {
    changeValue(state, name, () => true);
  });
};

const mutatorSetFalse = ([fields], state, { changeValue }) => {
  fields.forEach((name) => {
    changeValue(state, name, (value) => false);
  });
};
const clearField = ([name], state, { changeValue }) => {
  changeValue(state, name, () => null);
};

const setField = ([name], state, { changeValue }) => {
  return (value) => {
    changeValue(state, name, () => value);
  };
};

let FlexLine = styled.div`
  display: flex;
  margin: 0 -10px;
  & > * {
    padding: 0 10px;
  }
`;

const FormHandler = (props) => {
  let [data, setData] = useState({ division_id: null, localization_id: null });
  let {
    onSubmit,
    mutators,
    ref,
    render,
    subscription,
    validate,
    initialValues,
    initialModulePermissionValues,
  } = props;
  useEffect(() => {
    setData({
      ...{ division_id: null, localization_id: null },
      ...initialValues,
      ...initialModulePermissionValues,
    });
  }, [
    JSON.stringify(initialValues),
    JSON.stringify(initialModulePermissionValues),
  ]);
  return (
    <Form
      onSubmit={onSubmit}
      mutators={mutators}
      ref={ref}
      validate={validate}
      initialValues={data}
      subscription={subscription}
      render={render}
    />
  );
};

class UsersAccountsForm extends AppComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      fetched: false,
      initialValues: { division_id: null, localization_id: null },
      addME: { open: false, data: {} },
      modalAddOSH: { open: false, data: {} },
      modalAddQE: { open: false, data: {} },
      deleteOSH: { open: false, data: {} },
      deleteME: { open: false, data: {} },
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.onWorkplaceChange = this.onWorkplaceChange.bind(this);
    this.deleteME = this.deleteME.bind(this);
    this.deleteOSH = this.deleteOSH.bind(this);
  }

  componentDidMount() {
    const { data = {} } = this.props;
    this.setState({
      fetched: true,
    });
  }

  deleteME() {
    let { deleteME } = this.props;
    let { entry_id } = this.state.deleteME;
    deleteME(entry_id);
    this.closeModal("deleteME");
  }

  deleteOSH() {
    let { deleteOSH } = this.props;
    let { entry_id } = this.state.deleteOSH;
    deleteOSH(entry_id);
    this.closeModal("deleteOSH");
  }

  handleSubmit(data) {
    const { handleSubmit = () => {} } = this.props;
    const newData = Object.keys(data)
      .filter((key) => /^(?!(module_)).+/.test(key))
      .reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {});

    const module = Object.keys(data)
      .filter((key) => /^module_/.test(key))
      .filter((key) => data[key])
      .map((key) => key.split("_")[1])
      .map((key) => parseInt(key));
    const formData = dataToFormData({ ...newData, module });
    handleSubmit(formData);
  }

  handleOpenModal(modalName) {
    return () => {
      this.setState({ [modalName]: { ...this.state[modalName], open: true } });
    };
  }

  handleCloseModal(modalName) {
    return (callback = () => {}) => {
      this.setState(
        { [modalName]: { ...this.state[modalName], open: false } },
        () => {
          callback();
        }
      );
    };
  }

  onWorkplaceChange(form) {
    if (this.props.businessView) {
      let { dispatch } = this.props;
      return (value) => {
        getWorkplacePermissions({ workplace_id: value })(dispatch).then(
          this.handleServerResponse({}, (action) => {
            let permissions = action.payload.data;
            for (let permission of permissions) {
              form.change(`permission_${permission.id}`, permission.active);
            }
          })
        );
      };
    }
  }

  render() {
    console.log(
      this.state.modalAddQE.data && this.state.modalAddQE.data.users_id
    );
    const {
      admin,
      businessView = false,
      acceptButtonName = "Zapisz",
      cancelFunction,
      boxTitle,
      data = { permissions: [] },
      handleAvatarAutoUpload,
      handleDeleteAvatar,
      localizations,
      message,
      osh_training_view,
      parent_workplaces,
      permissions = [],
      workplaces,
      dictionary,
      me,
      osh,
      qe,
      saveME = () => {},
      addOSH = () => {},
      addQE = () => {},
      deleteME,
      deleteOSH,
      publicView,
      readOnly = false,
      users_id,
      types = [],
      qeTypes = [],
      getSections,
      // users,
    } = this.props;
    const localizationsData = [{ id: null, name: "Bez limitu" }].concat(
      localizations
    );
    const parentWorkplacesData = [{ id: null, name: "Brak" }].concat(
      parent_workplaces
    );
    const { avatar, ...initialValues } = data;

    let initialModulePermissionValues = initialPermissionValues(permissions);

    return (
      <FormHandler
        onSubmit={this.handleSubmit}
        mutators={{
          clearField,
          mutatorSetTrue,
          mutatorSetFalse,
          setField,
          ...arrayMutators,
        }}
        ref={(form) => (this.form = form)}
        validate={(values) => {
          let { active, email } = values;
          const errors = {};
          if (active && (!email || !email.length)) {
            errors.active = "Nie można aktywować konta bez adresu e-mail";
          }
          return errors;
        }}
        initialValues={initialValues}
        initialModulePermissionValues={initialModulePermissionValues}
        subscription={{
          submitting: true,
          pristine: true,
        }}
        render={({ form, handleSubmit, pristine, invalid, values }) => {
          return (
            <>
              <form onSubmit={handleSubmit}>
                {admin && (
                  <>
                    <BoxesWrapper>
                      <div>
                        <h3>Dane dostępowe do systemu</h3>
                        <Field
                          name="active"
                          type="checkbox"
                          render={({ input, meta, ...rest }) => (
                            <StyledInputCheckBox
                              {...input}
                              label="Konto aktywne"
                              error={meta.error && meta.touched}
                              helperText={
                                meta.error && meta.touched ? meta.error : null
                              }
                              handleChange={(event) => {
                                input.onChange(event.target.checked);
                              }}
                              checked={input.value}
                            />
                          )}
                        />
                      </div>
                    </BoxesWrapper>
                    <Permissions form={form} modulePermissions={permissions} />
                  </>
                )}
                <DefaultDataComponent
                  data={data}
                  workplaces={workplaces}
                  handleAvatarAutoUpload={handleAvatarAutoUpload}
                  handleDeleteAvatar={handleDeleteAvatar}
                  parentWorkplacesData={parentWorkplacesData}
                  localizationsData={localizationsData}
                  onWorkplaceChange={this.onWorkplaceChange(form)}
                  publicView={publicView}
                  readOnly={readOnly}
                  getSections={getSections}
                />
                <DetailDataComponent
                  values={values}
                  form={form}
                  readOnly={readOnly}
                />
                {me && (
                  <MESegment
                    addME={this.openModal("addME", {
                      data: {},
                    })}
                    businessView={businessView}
                    data={me}
                    dictionary={dictionary}
                    deleteME={(id) => {
                      this.openModal("deleteME", { entry_id: id })();
                    }}
                    editME={(item) =>
                      this.openModal("addME", {
                        data: item,
                      })
                    }
                  />
                )}
                {osh && (
                  <OSHSegment
                    addOSH={this.openModal("modalAddOSH", {
                      data: { users_id },
                    })}
                    data={osh}
                    deleteOSH={(id) => {
                      this.openModal("deleteOSH", { entry_id: id })();
                    }}
                    formTextBox={
                      <>
                        <FieldTextareaBox
                          name={"osh_training_description"}
                          label={"Dodatkowe szkolenia / kursy"}
                          minHeight={null}
                        />
                        <ButtonWrapper>
                          <Button onClick={this.saveOSHDescription}>
                            Zapisz
                          </Button>
                        </ButtonWrapper>
                      </>
                    }
                  />
                )}
                {qe && (
                  <QEUserSegment
                    data={qe}
                    addNewQE={this.openModal("modalAddQE", {
                      data: { users_id },
                    })}
                    editQE={(item) =>
                      this.openModal("modalAddQE", {
                        data: item,
                      })
                    }
                    deleteQE={(id) => {
                      this.openModal("deleteQE", { entry_id: id })();
                    }}
                  />
                )}

                {/* {false && <QualificationEntitlements />} */}
                <ButtonWrapper>
                  <Button disabled={readOnly} type="submit" bsStyle="primary">
                    {acceptButtonName}
                  </Button>
                  {cancelFunction ? (
                    <Button onClick={cancelFunction}>Anuluj</Button>
                  ) : (
                    <LinkContainer to="/business/users-accounts" exact>
                      <Button>Anuluj</Button>
                    </LinkContainer>
                  )}
                </ButtonWrapper>
              </form>
              <SaveMedicalExaminationsFormModal
                businessView={businessView}
                saveME={saveME}
                open={this.state.addME.open}
                data={this.state.addME.data}
                closeModal={this.closeModal("addME")}
              />
              <AddOSH
                addOSH={addOSH}
                businessView={businessView}
                open={this.state.modalAddOSH.open}
                data={this.state.modalAddOSH.data}
                types={types}
                closeModal={this.closeModal("modalAddOSH")}
              />
              <AddQE
                addQE={addQE}
                businessView={businessView}
                open={this.state.modalAddQE.open}
                data={this.state.modalAddQE.data}
                closeModal={this.closeModal("modalAddQE")}
                isAdd={
                  !(
                    this.state.modalAddQE.data &&
                    this.state.modalAddQE.data.users_id
                  )
                }
                types={qeTypes}
              />
              <ModalDelete
                open={this.state.deleteME.open}
                title="Usuń badanie"
                description={"Czy chcesz usunąć dany wpis?"}
                handleAccept={this.deleteME}
                handleClose={this.closeModal("deleteME")}
              />
              <ModalDelete
                open={this.state.deleteOSH.open}
                title="Usuń szkolenie"
                description={"Czy chcesz usunąć dany wpis?"}
                handleAccept={this.deleteOSH}
                handleClose={this.closeModal("deleteOSH")}
              />
            </>
          );
        }}
      />
    );
  }

  componentWillUnmount() {}
}

export let UserForm = connect((store) => ({}))(UsersAccountsForm);

export default class UsersAccountsFormWithLayout extends AppComponent {
  render() {
    let { message, boxTitle } = this.props;

    return (
      <LayoutContent>
        {message && message.text && (
          <Alert bsStyle={message.className}>{message.text}</Alert>
        )}
        <Box title={boxTitle}>
          <UserForm {...this.props} />
        </Box>
      </LayoutContent>
    );
  }
}

UsersAccountsForm.propTypes = {
  entry_id: PropTypes.number,
  boxTitle: PropTypes.string.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    workplace_id: PropTypes.number,
    localization_id: PropTypes.number,
    sector_id: PropTypes.number,
    parent_workplace_id: PropTypes.number,
    parent_user_id: PropTypes.number,
  }),
  handleSubmit: PropTypes.func,
  parent_workplaces: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.node,
      name: PropTypes.string,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.node,
          name: PropTypes.string,
          children: PropTypes.arrayOf(
            PropTypes.shape({
              value: PropTypes.node,
              name: PropTypes.string,
              children: PropTypes.arrayOf(
                PropTypes.shape({
                  value: PropTypes.node,
                  name: PropTypes.string,
                  children: PropTypes.arrayOf(
                    PropTypes.shape({
                      value: PropTypes.node,
                      name: PropTypes.string,
                    })
                  ),
                })
              ),
            })
          ),
        })
      ),
    })
  ),
  workplaces: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.node,
      name: PropTypes.string,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.node,
          name: PropTypes.string,
          children: PropTypes.arrayOf(
            PropTypes.shape({
              value: PropTypes.node,
              name: PropTypes.string,
              children: PropTypes.arrayOf(
                PropTypes.shape({
                  value: PropTypes.node,
                  name: PropTypes.string,
                  children: PropTypes.arrayOf(
                    PropTypes.shape({
                      value: PropTypes.node,
                      name: PropTypes.string,
                    })
                  ),
                })
              ),
            })
          ),
        })
      ),
    })
  ),
  localizations: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.node,
      name: PropTypes.string,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.node,
          name: PropTypes.string,
        })
      ),
    })
  ),
  users: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.node,
      name: PropTypes.string,
    })
  ),
};
