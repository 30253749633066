import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import AppComponent from "components/AppComponent";
import PageHeader from "components/PageHeader";

import { deleteRAMethod, getRAMethodsList } from "actions/riskAssessmentAction";

import { AddButton, DeleteButton } from "components/Buttons";
import { ButtonWrapper } from "components/Wrappers";
import { LinkContainer } from "components/LinkContainer";
import { LayoutContent } from "components/Layout";
import { TableListPage } from "components/Tables";
import Pagination from "components/Pagination";
import Modal from "components/Modal/SimpleAccept";
import { setUrls } from "../_staticData";
import SideMenu from "../_SideMenu";

class List extends AppComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      modal: {
        open: false,
        entry_id: null,
      },
      data: [],
      sort: 0,
      sortData: [],
      urls: setUrls(),
    };

    this.pageChange = this.pageChange.bind(this);
    this.deleteRAMethod = this.deleteRAMethod.bind(this);
    this.requestDelete = this.requestDelete.bind(this);
    this.closeDeleteRequest = this.closeDeleteRequest.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    getRAMethodsList({ readOnly: true })(dispatch);
    this.updateMessage();
  }

  pageChange(data) {
    this.setState({ data });
  }

  deleteRAMethod(id) {
    const { dispatch } = this.props;
    const {
      modal: { entry_id },
    } = this.state;
    deleteRAMethod(entry_id)(dispatch).then(this.handleServerResponse());
  }

  requestDelete(entry_id) {
    return () => {
      this.setState({ modal: { ...this.state.model, open: true, entry_id } });
    };
  }

  closeDeleteRequest() {
    this.setState({
      modal: { ...this.state.model, open: false, entry_id: null },
    });
  }

  render() {
    let { data, modal, message, urls } = this.state;
    let { methods, permission = {} } = this.props;
    console.log("permission", permission);
    return (
      <LayoutContent SideMenu={<SideMenu />} message={message}>
        <PageHeader>Szablony metod oceny ryzyka zawodowego</PageHeader>
        <ButtonWrapper>
          <LinkContainer to={urls.METHODADD}>
            <AddButton disabled={!permission[35]} name="Nowa metoda ORZ" />
          </LinkContainer>
        </ButtonWrapper>
        <TableListPage>
          <thead>
            <th>L.p.</th>
            <th>Nazwa</th>
            <th>Usuń</th>
          </thead>
          <tbody>
            {data.map((item, index) => {
              const readOnly =
                Boolean(item.business_id === 0) || !permission[37];
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>
                    <Link to={urls.METHODEDIT(item.id)}>{item.name}</Link>{" "}
                  </td>
                  <td>
                    <DeleteButton
                      disabled={readOnly}
                      name="Usuń"
                      onClick={this.requestDelete(item.id)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </TableListPage>
        <Pagination handlePageChange={this.pageChange} data={methods} />
        <Modal
          title="Usuń metodę"
          description={"Czy chcesz usunąć dany wpis?"}
          open={modal.open}
          handleAccept={this.deleteRAMethod}
          handleClose={this.closeDeleteRequest}
        />
      </LayoutContent>
    );
  }
}

export default connect((store) => ({
  methods: store.riskAssessment.methods,
  permission: store.riskAssessment.permission,
  message: store.message,
}))(List);
