import React from "react";
import Button from "react-bootstrap/lib/Button";
import Table from "react-bootstrap/lib/Table";

import styled from "styled-components";
import moment from "moment";

import { LinkContainer } from "components/LinkContainer";

import { StyledTableWrapper } from "components/ListComponents";
import { TableSortHeader } from "components/TableSortable";
import { ButtonWithIcon } from "components/Buttons";
import { LeftPadding, ButtonWrapper } from "components/Wrappers";
import {
  StyledLabel,
  StyledInputBox,
  StyledTextareaBox,
} from "style/styleComponents/Form/input";
import { media } from "style/style-utils/index";

let getDate = (date, format = "YYYY-MM-DD") => {
  if (!date) {
    return null;
  }
  return moment(date).format(format);
};

const StyledTable = styled(({ className, children, ...rest }) => (
  <Table className={className} bordered condensed hover {...rest}>
    {children}
  </Table>
))`
  & tr {
    &:nth-child(1) > td {
      font-weight: 700;
      vertical-align: top;
    }
    border-top: 1px solid ${(props) => props.theme.tableBorderColor};

    & td {
      padding: 8px;
      border-left: 1px solid ${(props) => props.theme.tableBorderColor};
    }
  }
`;

const BoxesWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
  flex-wrap: wrap;

  & > * {
    padding: 0 10px;
    flex-basis: 50%;
    ${media.sm`
      flex-basis: 100%;
      margin-bottom:10px;
    `}
  }
`;

let getHeaders = ({ deleteME }) => {
  let headers = [
    { name: "L.p.", prop: "" },
    {
      name: "Rodzaj badania",
      prop: "medical_examinations_type_name",
    },
    {
      name: "Data wykonania",
      prop: "date_execution",
    },
    {
      name: "Termin ważności",
      prop: "date_next",
    },
    { name: "Zdolność do pracy", prop: "ability_to_work_id" },
    {
      name: "Informacje dodatkowe",
      prop: "date_nexts_medical_examinations",
    },
  ];

  if (deleteME) {
    headers.push({
      name: "Usuń",
    });
  }

  return headers;
};

export default class MESegment extends React.PureComponent {
  render() {
    let {
      dictionary,
      data,
      addME,
      editME,
      referralLink,
      deleteME,
      permission = {},
      businessView,
    } = this.props;
    let {
      date_last_initial,
      date_next_rest,
      date_last_rest,
      ability_to_work_name,
      date_next,
      additional_description,
    } = data;
    let user_me = data.user_me ? data.user_me : [];

    return (
      <>
        <h3>Profilaktyczne badania lekarskie medycyny pracy</h3>{" "}
        <StyledLabel>Aktualne badania</StyledLabel>
        <LeftPadding>
          <BoxesWrapper>
            <div>
              <StyledInputBox
                disabled
                label="Data wstepnych badań lekarskich"
                value={getDate(date_last_initial) || ""}
              />

              <StyledInputBox
                disabled
                label="Data aktualnych okresowych lub kontrolnych badań lekarskich"
                value={getDate(date_last_rest) || ""}
              />
              <StyledInputBox
                disabled
                label="Orzeczenie o zdolności do pracy"
                value={
                  (
                    dictionary.ability_to_work.find((i) => {
                      return i.id == data.ability_to_work_id;
                    }) || {}
                  ).name || null
                }
              />
              <StyledInputBox
                disabled
                label="Termin następnych badań"
                value={getDate(date_next) || ""}
              />
            </div>
            <div>
              <StyledTextareaBox
                disabled
                label="Informacje dodatkowe / uwagi z orzeczenia lekarskiego"
                value={additional_description}
              />

              {referralLink ? (
                <LinkContainer
                  to={
                    "/medical-examination/referral" +
                    `?user_id=${data.users_id}`
                  }
                  exact
                >
                  <Button disabled={!(businessView || permission[53])}>
                    Wystaw skierowanie
                  </Button>
                </LinkContainer>
              ) : null}
            </div>
          </BoxesWrapper>
        </LeftPadding>
        <ButtonWrapper>
          <Button onClick={addME} disabled={!(businessView || permission[38])}>
            Dodaj nowe badanie
          </Button>
        </ButtonWrapper>
        <h5>Historia badań</h5>
        <StyledTableWrapper>
          <StyledTable striped bordered hover>
            <TableSortHeader
              headers={getHeaders({ deleteME })}
              data={user_me.map((item, index) => {
                return { ...item, logicIndex: index };
              })}
              render={(sortedData) => {
                return sortedData.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>{getDate(item.date_issue)}</td>
                      <td>{getDate(item.date_next)}</td>
                      <td>
                        {(
                          dictionary.ability_to_work.find((i) => {
                            return i.id == item.ability_to_work_id;
                          }) || {}
                        ).name || null}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <Button
                          style={{ display: "inline-block" }}
                          onClick={editME(item)}
                        >
                          Pokaż
                        </Button>
                      </td>
                      {deleteME && (
                        <td style={{ textAlign: "center" }}>
                          <ButtonWithIcon
                            onClick={() => {
                              deleteME(item.id);
                            }}
                            icon="fa fa-trash"
                          />
                        </td>
                      )}
                    </tr>
                  );
                });
              }}
            ></TableSortHeader>
          </StyledTable>
        </StyledTableWrapper>
      </>
    );
  }
}
